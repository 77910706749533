import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { NavLink, useHistory, withRouter } from 'react-router-dom'
import verifyUserThunk from '../../redux-thunk/verifyUserThunk'
import styles from './login.module.css'

function Login(props) {
    const [email, setemail] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()
    return (
        <>
            <div className={styles.loginRoot}>
                <div className={styles.AboutUs}>
                    <p className={styles.heading}>About Us</p>
                    <div className={styles.line}></div>
                    <p>
                        Digizen is committed to helping individuals live a healthy life while integrating sustainability
                        practices in their lifestyle. Complete the surveys to discover your Carbon Footprint and
                        download the app to discover ways to reduce your Carbon Footprint now!
                    </p>

                    <button className={styles.OrangeButton}>Download App</button>
                </div>
                <div className={styles.loginCard}>
                    <>
                        <p className={styles.headingCard}>Login</p>
                        <input
                            className={styles.loginInput}
                            type="email"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setemail(e.target.value)}
                        />
                        <br />
                        <button
                            className={styles.loginButton}
                            onClick={async () => {
                                const token = await verifyUserThunk(dispatch, email)
                                // console.log('token',token);
                                history.push({
                                    pathname: `loadSession/${token}/survey?view=digizen`
                                })
                            }}
                        >
                            Login
                        </button>
                        <NavLink className={styles.Orangetext} to="/register">
                            New User?
                        </NavLink>
                    </>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (payload) => dispatch(payload),
        setTokenAndUserData: (email) => {
            dispatch({
                type: 'GET_EMAIL',
                emailId: email
            })
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
