import React from 'react'
import { Box, Button, Card, CardContent, Container, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Img from '../assets/inflateEarth.jpg'
import { FormatListBulleted } from '@material-ui/icons'
const VirtualMeetLanding = () => {
    return (
        <Container
            sx={{
                py: 1,
                width: '100%',
                display: 'flex',
                gap: 4,
                margin: 0,
                maxWidth: '100% !important',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                alignItems: { sm: 'center', md: 'flex-start' }
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '40%' },
                    height: '100%',
                    mb: 4,
                    borderRadius: 2
                }}
            >
                <Box
                    component="img"
                    width="100%"
                    height="100%"
                    src={Img}
                    alt="Virtual Meet Banner"
                    sx={{
                        objectFit: 'contain'
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { sm: '100%', md: '60%' }
                }}
            >
                <Stack alignItems="center" spacing={2} sx={{ mb: 6 }}>
                    <Typography variant="h3" component="h1" align="center" fontWeight="bold">
                        Join us for the Inauguration of “Inflate the Earth” campaign promoting sustainable living
                    </Typography>

                    <Typography variant="h6" color="text.secondary" align="center">
                        Our campaign aims to raise awareness about the importance of sustainable living and provide
                        practical tips and resources to help you make eco-friendly choices in your daily life. The first
                        phase of the campaign will run from December 4 - 20 December 2024.
                    </Typography>

                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            px: 4,
                            py: 2,
                            mt: 2,
                            fontSize: '1.125rem',
                            fontWeight: 600
                        }}
                    >
                        <a href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_OTU5OGU0ODctYmI4NS00YTBhLTg2ZDktNGUxYWIxY2EwZDYw%40thread.v2%2F0%3Fcontext%3D%257B%2522Tid%2522%253A%2522404b1967-6507-45ab-8a6d-7374a3f478be%2522%252C%2522Oid%2522%253A%25223e5af91c-6c56-4e4d-95c0-2e85f1302734%2522%252C%2522IsBroadcastMeeting%2522%253Atrue%252C%2522role%2522%253A%2522a%2522%257D%26btype%3Da%26role%3Da%26anon%3Dtrue&type=meetup-join&deeplinkId=34961ec3-1437-4030-90c4-340341b2e31a&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true#">
                            <Typography color="#fff" fontWeight="600">
                                Join Virtual Meet{' '}
                            </Typography>
                        </a>
                    </Button>
                </Stack>
                <Box sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                        <FormatListBulleted color="primary" />
                        <Typography variant="h5" fontWeight="bold">
                            Activities Included
                        </Typography>
                    </Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 3
                        }}
                    >
                        {[
                            {
                                title: 'Workshops:',
                                description: 'Learn about composting, recycling, and energy conservation.'
                            },

                            {
                                title: 'Guest Speakers:',
                                description: 'Hear from experts in environmental science and sustainable living.'
                            },

                            {
                                title: 'Interactive Sessions:',
                                description:
                                    'Participate in discussions and Q&A sessions with sustainability advocates.'
                            },

                            {
                                title: 'Fitness Desks:',
                                description: ' Living healthy and sustainable life.'
                            }
                        ].map((activity, index) => (
                            <Box
                                key={index}
                                sx={{
                                    flexBasis: {
                                        xs: '100%',
                                        md: 'calc(50% - 12px)'
                                    }
                                }}
                            >
                                <Card sx={{ minHeight: '130px', maxHeight: '130px' }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            {activity.title}
                                        </Typography>
                                        <Typography color="text.secondary">{activity.description}</Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Card elevation={3} sx={{ mb: 6, mt: 5 }}>
                    <CardContent>
                        <Grid width={'100%'} justifyContent="start">
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                                <Typography variant="h6">
                                    Don't miss this incredible opportunity to learn, grow and win!
                                </Typography>
                            </Box>
                            <Typography style={{ display: 'flex', justifyContent: 'start' }} color="text.secondary">
                                <ul style={{ textAlign: 'start' }}>
                                    <li>
                                        <span style={{ fontWeight: '800' }}> Participation –</span> Badges and GEMs for
                                        active participation in all webinars
                                    </li>

                                    <li>
                                        <span style={{ fontWeight: '800' }}>Lucky Draw –</span> 5 Wearables to be won
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: '800' }}>Volunteers – </span> T-Shirts and Badges to
                                        be earned
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid width={'100%'} justifyContent="start">
                            <a href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_OTU5OGU0ODctYmI4NS00YTBhLTg2ZDktNGUxYWIxY2EwZDYw%40thread.v2%2F0%3Fcontext%3D%257B%2522Tid%2522%253A%2522404b1967-6507-45ab-8a6d-7374a3f478be%2522%252C%2522Oid%2522%253A%25223e5af91c-6c56-4e4d-95c0-2e85f1302734%2522%252C%2522IsBroadcastMeeting%2522%253Atrue%252C%2522role%2522%253A%2522a%2522%257D%26btype%3Da%26role%3Da%26anon%3Dtrue&type=meetup-join&deeplinkId=34961ec3-1437-4030-90c4-340341b2e31a&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true#">
                                <Typography
                                    style={{ display: 'flex', justifyContent: 'start', textDecoration: 'underline' }}
                                    color="#4e84c4"
                                >
                                    For those unable to attend in person, we invite you to join us virtually by clicking
                                    here.
                                </Typography>
                            </a>
                            <Typography style={{ display: 'flex', justifyContent: 'start' }} color="text.secondary">
                                No contribution is too small, join us in making a positive impact by participating in
                                InflateTheEarth inauguration ceremony!
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
}

export default VirtualMeetLanding
