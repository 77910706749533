import { CssBaseline, makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../util/routes'
import Header from '../common/header/header'
// import backIcon from "../../assets/icons/back-icon.png";
const useStyles = makeStyles(() => ({
    fullBodyContainer: {
        width: '100%',
        height: '100vh',
        // overflow: 'auto',
        scrollbarWidth: 'none'
    }
}))
function Layout({ children }) {
    const classes = useStyles()
    const location = useLocation()

    const { inDigizen } = useSelector((state) => state.applicationInfo)

    return (
        <div
            className={classes.fullBodyContainer}
            style={
                inDigizen ||
                location.pathname === '/' ||
                location.pathname === '/banner' ||
                location.pathname === '/banner/EmployeeCommute' ||
                location.pathname === '/banner/SustainabilityCourse' ||
                location.pathname === ROUTES.TERMS ||
                location.pathname === ROUTES.PRIVACY ||
                location.pathname === ROUTES.PRIVACY_PDF ||
                location.pathname === ROUTES.TERMS_PDF ||
                location.pathname.startsWith(ROUTES.EVENTS_SCREEN) ||
                location.pathname === ROUTES.VOLUNTEER_FORM ||
                location.pathname === ROUTES.EVENT ||
                location.pathname === ROUTES.VOLUNTEER_RESULT
                    ? { paddingTop: 0 }
                    : { paddingTop: 64 }
            }
        >
            {inDigizen ||
            location.pathname === '/' ||
            location.pathname === '/banner' ||
            location.pathname === '/banner/EmployeeCommute' ||
            location.pathname === '/banner/SustainabilityCourse' ||
            location.pathname === ROUTES.TERMS ||
            location.pathname === ROUTES.PRIVACY ||
            location.pathname === ROUTES.PRIVACY_PDF ||
            location.pathname === ROUTES.TERMS_PDF ||
            location.pathname.startsWith(ROUTES.EVENTS_SCREEN) ||
            location.pathname === ROUTES.VOLUNTEER_FORM ||
            location.pathname === ROUTES.EVENT ||
            location.pathname === ROUTES.VOLUNTEER_RESULT ? null : (
                <Header />
            )}
            <CssBaseline />
            {children}
        </div>
    )
}
export default Layout
