// --- MUI
import {
    Checkbox,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    MenuItem,
    OutlinedInput,
    Select,
    Tooltip,
    Typography
} from '@mui/material'
// -- PACKAGES
import _ from 'lodash'
import styled from 'styled-components'
import { checkIsEmpty } from '../../util/textHelpers'
import { useState } from 'react'
import city_list from '../../store/CITY_LIST.json'

/**
 * 
SAMPLE:

const initialForm = [
    {
        name: 'name',
        value: '', //value can only be string or array
        label: 'Name',
        isRequired: true,
        jsx: 'input',
        type: 'text',
        otherValidation: (eachForm) => {
          //return a customerrormessage is error, return empty string if no error
          }, //set to null if u dont require otherValidation
         isTouched: false,
    isValid: false,
        placeholder: ""
        wrapperComponent: (content) => <MyComponent>{content}</MyComponent> //<-- optional,
        hide: true/false,//<--optional
    },
    {
        name: 'email',
        value: '',
        label: 'Email',
        isRequired: true,
        jsx: 'input',
        type: 'email',
        otherValidation: null,
         isTouched: false,
    isValid: false,
    },
    {
        name: 'designation',
        value: '',
        label: 'Designation',
        isRequired: true,
        jsx: 'input',
        type: 'text',
        otherValidation: null,
         isTouched: false,
    isValid: false,
    },
    {
        name: 'dashboardAccess',
        value: ['Environmental Dashboard'],  //<-- has to match value
        label: 'Dashboard Access',
        isRequired: false,
        disabled: ['Environmental Dashboard'], //<-- has to match value
        jsx: 'checkbox',
        options: [
            { value: 'Environmental Dashboard', label: 'Environmental Dashboard' },
            { value: 'Social Dashboard', label: 'Social Dashboard' },
            { value: 'Governance Dashboard', label: 'Governance Dashboard' }
        ],
        otherValidation: null,
         isTouched: false,
    isValid: false,
    },
        {
        name: 'dashboardAccess2',
        value: 'Environmental Dashboard',  //<-- has to match value
        label: 'Dashboard Access',
        isRequired: false,
        jsx: 'select',
        options: [
            { value: 'Environmental Dashboard', label: 'Environmental Dashboard' },
            { value: 'Social Dashboard', label: 'Social Dashboard' },
            { value: 'Governance Dashboard', label: 'Governance Dashboard' }
        ],
        otherValidation: null,
         isTouched: false,
    isValid: false,
    }
        },
]
 */

//Wrap this component around a
/**
 * 
<form noValidate autoComplete="off">
...and insert in your own buttons
</form>
 */

function FormBuilder({ form = [], setForm = () => {}, isLoading = false, ...props }) {
    const handleChange = (value, name, jsx, config) => {
        if (name === 'country') {
            setForm((prev) =>
                prev.map((eachPrev) => {
                    if (eachPrev.name === name) {
                        const [boolPassed] = passedValidation({ ...eachPrev, value: value })
                        return {
                            ...eachPrev,
                            value: value,
                            isTouched: true,
                            isValid: boolPassed
                        }
                    } else if (eachPrev.name === 'city') {
                        let formatted_city_list = city_list[value].map((eachCity) => {
                            return {
                                value: eachCity,
                                label: eachCity
                            }
                        })
                        return { ...eachPrev, options: formatted_city_list, disabled: false }
                    } else {
                        return { ...eachPrev, isValid: passedValidation(eachPrev)[0] }
                    }
                })
            )
        } else {
            return setForm((prev) =>
                prev.map((eachPrev) => {
                    if (eachPrev.name === name) {
                        const [boolPassed] = passedValidation({ ...eachPrev, value: value })
                        switch (true) {
                            case jsx === 'checkbox':
                                if (value === 'All') {
                                    return {
                                        ...eachPrev,
                                        value: !config?.checkedAll ? [] : eachPrev.options.map((each) => each.value)
                                    }
                                }
                                return {
                                    ...eachPrev,
                                    value: eachPrev.value.includes(value)
                                        ? eachPrev.value.filter((e) => e !== value)
                                        : [...eachPrev.value, value],
                                    isTouched: true,
                                    isValid: boolPassed
                                }
                            default:
                                return {
                                    ...eachPrev,
                                    value: value,
                                    isTouched: true,
                                    isValid: boolPassed
                                }
                        }
                    } else {
                        return { ...eachPrev, isValid: passedValidation(eachPrev)[0] }
                    }
                })
            )
        }
    }

    return form.map((eachForm, i) => {
        let content = ''
        switch (true) {
            case eachForm.jsx === 'input':
            case eachForm.jsx === 'number':
                content = <InputSubForm eachForm={eachForm} handleChange={handleChange} key={i} jsx={eachForm.jsx} />
                break
            case eachForm.jsx === 'select':
                content = <SelectSubForm eachForm={eachForm} handleChange={handleChange} key={i} jsx={eachForm.jsx} />
                break
            case eachForm.jsx === 'checkbox':
                content = <CheckboxSubForm eachForm={eachForm} handleChange={handleChange} key={i} jsx={eachForm.jsx} />
                break
            default:
                content = <></>
        }
        return content
    })
}

export default FormBuilder

// ------------------------------------------------ VALIDATION ----------------------------------------------------//
//Returns true if success, false if error
const passedIsRequired = (isRequired = false, value = '') => {
    if (!isRequired) {
        return true
    }
    //array to check that array items are not empty
    if (Array.isArray(value) && typeof value !== 'object') {
        return !checkIsEmpty(value)
    }
    return value?.length !== 0
}

const passedEmailValidation = (eachForm) => {
    const { type, value } = eachForm
    if (type !== 'email') {
        return true
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@tcs\.com$/ //allow only tcs.com
    // const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/; //for all email
    return value === '' || (value.includes('@') && value.includes('.') && emailPattern.test(value.toLowerCase()))
}

//Returns [true, ""] if success, [false, "customErrorMessage"] if error
const passedValidation = (eachForm) => {
    const { value, type, otherValidation = null, isRequired = false, jsx = '', label = '' } = eachForm
    let customErrorMessage = ''
    //---- CHECK 1: isRequired field
    const checkIsSuccess1 = passedIsRequired(isRequired, value)
    customErrorMessage = checkIsSuccess1 ? customErrorMessage : `${label} is required`
    //---- CHECK 2: custom otherValidation will return a string. empty string if no customerrormessage,
    let checkIsSuccess2 = true
    if (otherValidation) {
        const errMsg = otherValidation(eachForm)
        customErrorMessage = errMsg ? errMsg : customErrorMessage
        checkIsSuccess2 = !Boolean(errMsg)
    }

    // ---- CHECK 3: if email, validate email
    const checkIsSuccess3 = passedEmailValidation(eachForm)
    customErrorMessage = checkIsSuccess3 ? customErrorMessage : 'Please enter a valid TCS email address'

    return [checkIsSuccess1 && checkIsSuccess2 && checkIsSuccess3, customErrorMessage]
}

// -------------------------- EXPORTED SUB COMPONENTS -------------------------------//

export function SelectSubForm({ eachForm = {}, handleChange = () => {}, jsx = 'select', ...props }) {
    const [boolPassed, customErrorMessage] = passedValidation(eachForm)
    return (
        <StyledFormControl error={eachForm.isTouched && !boolPassed} required={eachForm.isRequired} {...props}>
            <StyledDiv>
                <label
                    htmlFor={eachForm.label}
                    style={{
                        textOverflow: 'ellipsis',
                        width: '100%',
                        marginTop: '10px',
                        fontWeight: '500',
                        fontSize: '13px',
                        color: 'black'
                    }}
                >
                    {eachForm.label}
                    {eachForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                </label>
            </StyledDiv>
            <StyledDiv>
                <Select
                    id={eachForm.label}
                    name={eachForm.name}
                    value={eachForm.value}
                    onChange={(e) => {
                        handleChange(e.target.value, eachForm.name, jsx)
                    }}
                    disabled={eachForm?.disabled || false}
                    placeholder={eachForm?.placeholder || ''}
                    displayEmpty
                    sx={{
                        height: '32px',
                        borderRadius: '5px',
                        borderColor: '#94A3B8',
                        width: '100%'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {eachForm.options.map((eachOption, i) => (
                        <MenuItem value={eachOption.value} key={i} disabled={eachOption?.disabled || false}>
                            {eachOption.label}
                        </MenuItem>
                    ))}
                </Select>
                {eachForm.isTouched && !boolPassed && (
                    <FormHelperText
                        error={true}
                        style={{ position: 'static', marginLeft: 0, textTransform: 'capitalize' }}
                    >
                        {customErrorMessage || `Please select ${_.lowerCase(eachForm?.label)}`}
                    </FormHelperText>
                )}
            </StyledDiv>
        </StyledFormControl>
    )
}

export function CheckboxSubForm({ eachForm = {}, handleChange = () => {}, jsx = 'checkbox', ...props }) {
    const [boolPassed, customErrorMessage] = passedValidation(eachForm)
    // const [checkedAll, setCheckedAll] = useState(false)
    return (
        <StyledFormControl error={eachForm.isTouched && !boolPassed} required={eachForm.isRequired} {...props}>
            <StyledDiv>
                {' '}
                <label
                    htmlFor={eachForm.label}
                    style={{
                        textOverflow: 'ellipsis',
                        width: '100%',
                        marginTop: '10px',
                        fontWeight: '500',
                        fontSize: '13px',
                        color: 'black'
                    }}
                >
                    {eachForm.label}
                    {eachForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                </label>
            </StyledDiv>
            <StyledDiv>
                <FormGroup className="checkbox-wrapper">
                    <Typography variant="p1" color="#27364BB2">
                        {eachForm?.explainationText}
                    </Typography>
                    {eachForm.options.map((eachOption, i) =>
                        eachForm?.toolTip ? (
                            <Tooltip
                                // ="#F8F8F8"
                                sx={{
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'red'
                                    }
                                }}
                                title={
                                    <>
                                        <Typography variant="b1">{eachOption?.toolTipContent?.header}</Typography>
                                        <ul>
                                            {eachOption?.toolTipContent?.content?.map((eachContent, i) => (
                                                <li key={i}>{eachContent}</li>
                                            ))}
                                        </ul>
                                    </>
                                }
                                placement="right"
                                TransitionComponent={Fade}
                            >
                                <FormControlLabel
                                    key={eachOption.name}
                                    control={
                                        <Checkbox
                                            id={eachForm.label}
                                            className="checkbox"
                                            checked={eachForm.value.includes(eachOption.value)}
                                            name={eachForm.name}
                                            onChange={(e) => handleChange(e.target.value, eachForm.name, jsx)}
                                            value={eachOption.value}
                                            disabled={
                                                eachForm.disabled ? eachForm.disabled.includes(eachOption.value) : false
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={`${eachOption.label}`}
                                />
                            </Tooltip>
                        ) : (
                            <FormControlLabel
                                key={eachOption.name}
                                control={
                                    <Checkbox
                                        id={eachForm.label}
                                        className="checkbox"
                                        checked={eachForm.value.includes(eachOption.value)}
                                        name={eachForm.name}
                                        onChange={(e) => handleChange(e.target.value, eachForm.name, jsx)}
                                        value={eachOption.value}
                                        disabled={
                                            eachForm.disabled ? eachForm.disabled.includes(eachOption.value) : false
                                        }
                                        color="primary"
                                    />
                                }
                                label={`${eachOption.label}`}
                            />
                        )
                    )}
                    {eachForm.isTouched && !boolPassed && (
                        <FormHelperText
                            error={true}
                            style={{ position: 'static', marginLeft: 0, textTransform: 'capitalize' }}
                        >
                            {customErrorMessage || `${_.capitalize(eachForm?.label)} is required`}
                        </FormHelperText>
                    )}
                </FormGroup>
            </StyledDiv>
        </StyledFormControl>
    )
}

export function InputSubForm({ eachForm = {}, handleChange = () => {}, jsx = 'text', ...props }) {
    const [boolPassed, customErrorMessage] = passedValidation(eachForm)
    return (
        <>
            <StyledFormControl
                error={eachForm.isTouched && !boolPassed}
                required={eachForm.isRequired}
                // sx={{ marginBottom: '20px' }}
                {...props}
            >
                <StyledDiv>
                    <label
                        style={{
                            textOverflow: 'ellipsis',
                            width: '100%',
                            marginTop: '10px',
                            fontWeight: '500',
                            fontSize: '13px',
                            color: 'black'
                        }}
                    >
                        {eachForm.label}
                        {eachForm.isRequired && <span style={{ color: 'red' }}>*</span>}
                    </label>
                </StyledDiv>
                <StyledDiv>
                    {' '}
                    <OutlinedInput
                        value={eachForm.value}
                        name={eachForm.name}
                        // onChange={(e) => handleChange(e, eachForm.name)}
                        onChange={(e) => {
                            const value = e.target.value
                            if (jsx === 'number' && !/^\d*\.?\d*$/.test(value)) {
                                return
                            } else {
                                handleChange(e.target.value, eachForm.name, jsx)
                            }
                        }}
                        type={eachForm.type}
                        placeholder={eachForm?.placeholder || ''}
                        sx={{ height: '32px', width: '100%' }}
                        disabled={eachForm?.disabled || false}
                    ></OutlinedInput>
                    {eachForm.isTouched && !boolPassed && (
                        <FormHelperText error={true} style={{ position: 'static', marginLeft: 0 }}>
                            {customErrorMessage}
                        </FormHelperText>
                    )}
                </StyledDiv>
            </StyledFormControl>
        </>
    )
}

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    @media (max-width: 400px) {
        width: 90%;
    }
`

export const StyledFormControl = styled(FormControl)`
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    @media (max-width: 400px) {
        flex-direction: column;
    }
    & .MuiFormControl-root.Mui-disabled {
        background: none !important;
    }

    // & .MuiInputBase-root.MuiInput-root {
    //     margin-top: 5px;
    // }
    // & .MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
    //     margin-left: 10px;
    //     width: 90%;
    // }

    .checkbox {
        lineheight: 8px;
        margin: 0;
        padding: 8px;
        padding-left: 0;
    }

    .checkbox-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 10px; /* Optional spacing */
    }
`
