import React from 'react'
import Header from '../../components/homepage_components/Header/Header'

const PrivacyPDF = () => {
    return (
        <div
            //   style={{
            //     width: "100vw",
            //     height: "100vh",
            //     display: "flex",
            //     alignItems: "center",
            //     justifyContent: "center",
            //   }}
            className="privacy_terms"
        >
            <Header className="header_div" />
            <div
                style={{
                    width: '90vw',
                    height: '90vh'
                }}
            >
                <iframe
                    src="https://storage.googleapis.com/tcs-salsa.appspot.com/MarketingAssets/Circle4life_Data_Privacy_Policy_v3.3.pdf"
                    height="100%"
                    width="100%"
                />
            </div>
        </div>
    )
}

export default PrivacyPDF
