import { Checkbox, FormControl, FormControlLabel, Link, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import FormBuilder from '../../components/Forms/FormBuilder'
import postVolunteerThunk from '../../redux-thunk/postVolunteerThunk'
import Country_list from '../../store/COUNTRY_LIST.json'
import { ROUTES } from '../../util/routes'
import { checkIsEmpty, extractKVfromForm } from '../../util/textHelpers'
import classes from './volunteer.module.css'
import icon_1 from '../../assets/Volunteer_assets/Icon_1.svg'
import icon_2 from '../../assets/Volunteer_assets/Icon_2.svg'
import icon_3 from '../../assets/Volunteer_assets/Icon_3.svg'
import icon_4 from '../../assets/Volunteer_assets/Icon_4.svg'
import icon_5 from '../../assets/Volunteer_assets/Icon_5.svg'
import icon_6 from '../../assets/Volunteer_assets/Icon_6.svg'
import icon_7 from '../../assets/Volunteer_assets/Icon_7.svg'
import icon_8 from '../../assets/Volunteer_assets/Icon_8.svg'

const INITIAL_FORM = [
    {
        name: 'emailId',
        value: '',
        label: 'Email ID',
        isRequired: true,
        jsx: 'input',
        type: 'email',
        otherValidation: null,
        isTouched: false,
        isValid: false,
        placeholder: 'xxxxxx@tcs.com',
        Image_Positions: {
            left_image: {
                left: '7%',
                top: '10%',
                src: icon_4
            },
            right_image: {
                right: '7%',
                top: '10%',
                src: icon_1
            }
        }
    },
    {
        name: 'areaOfInterest',
        value: '',
        label: 'Area Of Interest',
        isRequired: true,
        jsx: 'checkbox',
        toolTip: true,
        explainationText: 'You can select multiple options',
        options: [
            {
                value: 'Event Facilitator/Logistics Support',
                label: 'Event Facilitator/Logistics Support',
                toolTipContent: {
                    header: 'Expected Tasks',
                    content: [
                        'Assist in registration of participants',
                        'Gather feedback form via physical/digital medium'
                    ]
                }
            },
            {
                value: 'Games Moderator',
                label: 'Games Moderator',
                toolTipContent: {
                    header: 'Expected Tasks',
                    content: [
                        'Assist in explaning instructions/rules of games',
                        'Validate accuracy of participants’ response'
                    ]
                }
            },
            {
                value: 'Sustainability Content Creation',
                label: 'Sustainability Content Creation',
                toolTipContent: {
                    header: 'Expected Tasks',
                    content: ['Develop content promoting sustainability', 'Research industry-related topics']
                }
            },
            {
                value: 'Eco-Action Ambassador, Coordinator ',
                label: 'Eco-Action Ambassador, Coordinator ',
                toolTipContent: {
                    header: 'Expected Tasks',
                    content: [
                        'Coordinate and execute environmental strategies',
                        'Drive participation and engagement, plant trees'
                    ]
                }
            },
            {
                value: 'Social Engagement & Communications',
                label: 'Social Engagement & Communications',
                toolTipContent: {
                    header: 'Expected Tasks',
                    content: ['Develop, implement engagement strategies', 'Manage social media platforms']
                }
            },
            {
                value: 'Marketing Collateral',
                label: 'Marketing Collateral',
                toolTipContent: {
                    header: 'Expected Tasks',
                    content: ['Design, develop and update campaign collaterals', 'Create engaging marketing content']
                }
            }
        ],
        otherValidation: null,
        isTouched: false,
        isValid: false,
        Image_Positions: {
            left_image: {
                left: '3%',
                top: '30%',
                src: icon_2
            },
            right_image: {
                right: '3%',
                top: '30%',
                src: icon_5
            }
        }
    },
    {
        name: 'country',
        value: '', //<-- has to match value
        label: 'Country',
        isRequired: true,
        jsx: 'select',
        options: Country_list,
        otherValidation: null,
        isTouched: false,
        isValid: false,
        placeholder: 'Country',
        Image_Positions: {
            left_image: {
                left: '3%',
                top: '60%',
                src: icon_8
            },
            right_image: {
                right: '3%',
                top: '60%',
                src: icon_3
            }
        }
    },
    {
        name: 'city',
        value: '', //<-- has to match value
        label: 'City',
        isRequired: true,
        jsx: 'select',
        options: [],
        otherValidation: null,
        isTouched: false,
        isValid: false,
        placeholder: 'City',
        disabled: true,
        Image_Positions: {
            left_image: {
                left: '7%',
                top: '80%',
                src: icon_6
            },
            right_image: {
                right: '7%',
                top: '80%',
                src: icon_7
            }
        }
    }
]

const Volunteer = () => {
    const [volunteerForm, setVolunteerForm] = useState(INITIAL_FORM)
    const [tnc, setTnc] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()

    const incompleteForm = !Object.values(volunteerForm).every((each) => each?.isValid)

    function handleSubmit(e) {
        e.preventDefault()
        if (incompleteForm) {
            return
        }
        setLoading(true)
        dispatch(postVolunteerThunk(extractKVfromForm(volunteerForm))).then((res) => {
            if (res.status === 'success') {
                history.push(ROUTES.VOLUNTEER_RESULT)
            }
        })
    }

    function handleLinkClick(e) {
        e.preventDefault()
        history.push(ROUTES.PRIVACY)
    }

    return (
        <div className={`${classes.volunteer_container} ${classes.volunteer_background_image}`}>
            <div className={classes.volunteer_card}>
                {volunteerForm.map((eachField, id) => {
                    return (
                        <div
                            id={`${eachField.name}_${id}_left`}
                            style={{
                                position: 'absolute',
                                top: `${eachField.Image_Positions.left_image.top}`,
                                left: `${eachField.Image_Positions.left_image.left}`
                            }}
                            className={`${classes.absolute_image} ${
                                !checkIsEmpty(eachField.value) ? classes.active_image : ''
                            } `}
                        >
                            <img src={eachField.Image_Positions.left_image.src} alt="icon" />
                        </div>
                    )
                })}
                {volunteerForm.map((eachField, id) => {
                    return (
                        <div
                            id={`${eachField.name}_${id}_right`}
                            style={{
                                position: 'absolute',
                                top: `${eachField.Image_Positions.right_image.top}`,
                                right: `${eachField.Image_Positions.right_image.right}`
                            }}
                            className={`${classes.absolute_image} ${
                                !checkIsEmpty(eachField.value) ? classes.active_image : ''
                            } `}
                        >
                            <img src={eachField.Image_Positions.right_image.src} alt="icon" />
                        </div>
                    )
                })}
                <div className={classes.form_container}>
                    <form noValidate autoComplete="off" className={classes.form}>
                        <Typography variant="h4" color="#64748B" align="center">
                            Impact through actions.
                            <Typography variant="h4" color="#64748B" align="center">
                                Volunteer with us
                            </Typography>
                        </Typography>
                        <Stack direction="column" gap={2} alignItems="center">
                            <FormBuilder form={volunteerForm} setForm={setVolunteerForm} />
                            <FormControl>
                                <FormControlLabel
                                    sx={{ alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={tnc}
                                            onChange={() => setTnc((prev) => !prev)}
                                        />
                                    }
                                    label={
                                        <Typography variant="caption" fontSize="12px">
                                            By completing this form, I agree to be contacted by Tata Consultancy
                                            Services Ltd and/or its affiliate companies ("TCS") and third parties based
                                            on the information provided above for the purpose of registration and for
                                            any related communication. I also understand that the session may be
                                            recorded. For details on how the information provided by me shall be used by
                                            TCS, I have read and understood the{' '}
                                            <Link onClick={handleLinkClick}>Privacy Terms</Link>.
                                        </Typography>
                                    }
                                />
                            </FormControl>
                            <button
                                className={classes.submit_button}
                                onClick={handleSubmit}
                                disabled={!tnc || incompleteForm}
                            >
                                {loading ? 'Please Wait...' : 'Sign me up, I want be a volunteer'}
                            </button>
                        </Stack>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Volunteer
