import { Avatar, Box, Container, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import Congo from '../../assets/congo.png'
import CustomCalendar from '../../components/CustomCalender'
import InfoBox from '../../components/Customized/InfoBox'
import updatePledgeLog from '../../redux-thunk/updatePledgeLog'
import getPledgeThunk from '../../redux-thunk/getPledgeThunk'
import { ROUTES } from '../../util/routes'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { firebaseRemoteConfig } from '../..'
import {Scrollbar} from 'smooth-scrollbar-react';
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        padding: 0,
        margin: 0,
        '&>img': {
            width: 'calc( 100% + 20px)'
        }
    },
    pledgeTitle: {
        color: '#111111',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 25,
        lineHeight: '29px',
        textAlign: 'center',
        marginBottom: 23,
        marginTop: 15
    },
    body: {
        fontSize: 14,
        marginBottom: 28,
        lineHeight: '16px',
        textAlign: 'left',
        color: '#8F8C8C'
    },
    infoRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 12,
        color: '#999999',
        lineHeight: '14px',
        padding: '4px 0px'
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    avatarRoot: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    calendarTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'bold',
        fontSize: 18,
        marginBottom: theme.spacing(1),
        color: '#333',
        textAlign: 'center'
    }
}))

function ExtensionLogPledge() {
    const classes = useStyles()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    // const { handleClick } = useContext(SnackbarContext)
    const [msg, setMsg] = useState('')
    const masterPledges = useSelector((state) => state.applicationInfo.pledgesList)
    const { pledgesList } = useSelector((state) => state.applicationInfo)
    const selectedPledge = useMemo(() => {
        return state && masterPledges.find((singlePledge) => singlePledge.pledge_id === state.id)
    }, [state, masterPledges])
    const selectedPledge1 = useMemo(() => {
        return state && pledgesList.find((singlePledge) => singlePledge.pledge_id === state.id)
    }, [state, pledgesList])
    useEffect(() => {
        firebaseRemoteConfig.fetchAndActivate()
        var tempText = firebaseRemoteConfig.getValue('cong_pledge_text1').asString()
        
        tempText = tempText.replace('pledge_earned_points', selectedPledge.pledge_per_points.toString())
        setMsg(tempText)
    }, [setMsg])
    if (!state || !state.id) {
        return <Redirect to={ROUTES.extensionPledges} />
    }

    return (
        <Box className={classes.container}>
          
            <Scrollbar
                className='custom-class'
            //alwaysShowTracks
        plugins={{
          overscroll: {
            effect: 'glow',
          },
        }}><></>
          <Container maxWidth="lg">
                <div style={{height: '480px'}}>
                    <Box className={classes.avatarRoot}>
                        {selectedPledge1.isPledgeCompleted === true ? (
                            // <div style={{ display:'flex', flexDirection:'column' , justifyContent:'center' , alignItems:'center' }}>
                            <img src={Congo} alt="congo" className={classes.avatar} />
                        ) : (
                            // {/* </div> */}
                            <Avatar className={classes.avatar} src={selectedPledge.pledge_image} alt="home" />
                        )}
                    </Box>
                    {selectedPledge1.isPledgeCompleted === true && (
                        <>
                            <Typography
                                style={{
                                    color: '#F26B3E',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                    textAlign: 'center'
                                }}
                            >
                                CONGRATULATIONS !
                            </Typography>
                            <Typography
                                style={{
                                    color: '#766D6D',
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    textAlign: 'center'
                                }}
                            >
                                {/* You have earned 50 points for completing the pledge: */}
                                {/* You have completed the pledge */}
                                {msg}
                            </Typography>
                        </>
                    )}
                    <Typography className={classes.calendarTitle}>{selectedPledge.pledge_name}</Typography>
                    <CustomCalendar
                        infoSection={
                            selectedPledge1.isPledgeCompleted !== true && (
                                <InfoBox>
                                    <Typography>
                                        Select the dates on which you have followed the pledge, and click Save.
                                    </Typography>
                                </InfoBox>
                            )
                        }
                        index={pledgesList.findIndex((x) => x.pledge_name === selectedPledge.pledge_name)}
                        selectedPledgeName={selectedPledge.pledge_name}
                        startDate={moment(selectedPledge.pledge_start_date).format('YYYY-MM-DD')}
                        loggedDates={selectedPledge.pledge_recorded_dates}
                        onSave={(selectedDates, unselectedDates) => () => {
                            dispatch(
                                updatePledgeLog({
                                    pledgeId: selectedPledge.pledge_id,
                                    selectedDates: selectedDates,
                                    unselectedDates: unselectedDates
                                })
                            ).then(() => {
                                dispatch(getPledgeThunk())
                                    .then((res) => {
                                        let index = res.pledgesList.findIndex(
                                            (x) => x.pledge_name === selectedPledge.pledge_name
                                        )
                                        if (res.pledgesList[index].isPledgeCompleted === null) {
                                            history.push(ROUTES.extensionPledges)
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err.message)
                                    })
                            })
                        }}
                        goToDashboard={() => {
                            history.push(ROUTES.extensionPledges)
                        }}
                        // openCamera={() => {
                        //     let message = 'openCamera'
                        //         window.webkit.messageHandlers.toggleMessageHandler.postMessage({
                        //             message: message
                        //         })
                        // }}
                    />
                </div>
                </Container>
                </Scrollbar>
            
        </Box>
    )
}

export default ExtensionLogPledge
