import axios from 'axios'
import { API_ROUTES } from '../util/routes'
function postVolunteerThunk(body) {
    return async function (dispatch, getState, { endpoint }) {
        const { data } = await axios.post('https://us-central1-tcs-salsa.cloudfunctions.net/webRoutes/volunteer', body)
        if (data.status !== 'success') {
            throw new Error('Invalid Error ' + data.message)
        }
        return data
    }
}

export default postVolunteerThunk
