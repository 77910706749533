import React from 'react'
import blog_white from '../../assets/homepage_assets/Blog.svg'
import podcast_white from '../../assets/homepage_assets/Podcast.svg'
import learnings_white from '../../assets/homepage_assets/Learnings.svg'
import story_white from '../../assets/homepage_assets/Story.svg'
import video_pink from '../../assets/homepage_assets/Video.svg'
import blog_pink from '../../assets/homepage_assets/Blog_pink.svg'
import podcast_pink from '../../assets/homepage_assets/Podcast_pink.svg'
import learnings_pink from '../../assets/homepage_assets/Learnings_pink.svg'
import story_pink from '../../assets/homepage_assets/Story_pink.svg'
import video_white from '../../assets/homepage_assets/Video_white.svg'
import tab_mobile from '../../assets/homepage_assets/tab_mobile.png'
import VerticalCurvedTab from './Tab'
import './tab.css'

const EngagementScreen = () => {
    const tabList = [
        {
            name: 'Story',
            description:
                "With our innovative app focused on sustainability, fitness, and purpose, users can seamlessly integrate eco-friendly practices into their daily routines, track their fitness goals, and engage in meaningful corporate social responsibility (CSR) activities. Whether it's finding sustainable products, accessing workout routines and nutrition plans, or discovering volunteer opportunities, our app empowers individuals to make positive contributions to the planet while enhancing their well-being. Join us in fostering a healthier, more sustainable, and purpose-driven world through our all-in-one platform. ",
            icon: story_white,
            active_icon: story_pink
        },
        {
            name: 'Blog',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst \n Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst',
            icon: blog_white,
            active_icon: blog_pink
        },
        {
            name: 'Video',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst \n Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst',
            icon: video_white,
            active_icon: video_pink
        },
        {
            name: 'Podcast',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst \n Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst',
            icon: podcast_white,
            active_icon: podcast_pink
        },
        {
            name: 'Learnings',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst \n Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Potenti nullam ac tortor vitae purus faucibus. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat. Sed viverra tellus in hac habitasse platea dictumst',
            icon: learnings_white,
            active_icon: learnings_pink
        }
    ]
    return (
        <div className="engagements_container">
            <div className="engagements_div">
                <img className="tab_mobile" src={tab_mobile} alt="c4l mobile app " />
                <VerticalCurvedTab tabList={tabList} />
            </div>
        </div>
    )
}

export default EngagementScreen
