/**
 * takes in the 'form' array and extract out into a single object {[name]: value, [name]: value} from it
 */
import cityList from '../store/CITY_LIST.json'

export const extractKVfromForm = (form) =>
    form.reduce((prev, curr) => {
        Object.assign(prev, { [curr.name]: curr.value })
        return prev
    }, {})

const EMPTY_STRING = ['', 'Not specified', 'Data not available', 'Not Provided', 'Not Calculable', 'Not Available']

export function checkIsEmpty(item) {
    if (Array.isArray(item)) {
        if (item.length === 0) {
            return true
        }
        if (typeof item[0] === 'object') {
            if (Object.keys(item).length === 0) {
                return true
            }
            return item.every((value) => checkIsEmpty(value))
        }
        return false
    }
    if (item === null) {
        return true
    }
    if (typeof item === 'string' && EMPTY_STRING.includes(item.trim())) {
        return true
    }
    if (typeof item === 'boolean') {
        return false
    }
    if (typeof item === 'object') {
        if (Object.keys(item).length === 0) {
            return true
        }
        //here we need a do a recursive loop into the item
        const { year, ...others } = item //to remove the key 'year'
        return Object.values(others).every((value) => checkIsEmpty(value))
    }
}
