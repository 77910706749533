import React from 'react'
import Header from '../../components/homepage_components/Header/Header'

const TermsPDF = () => {
    return (
        <div className="privacy_terms">
            <Header className="header_div" />
            <div
                style={{
                    width: '90vw',
                    height: '90vh'
                }}
            >
                <iframe
                    src="https://storage.googleapis.com/tcs-salsa.appspot.com/MarketingAssets/Circle4life_Terms_of_Use_v2.0.pdf"
                    height="100%"
                    width="100%"
                />
            </div>
        </div>
    )
}

export default TermsPDF
