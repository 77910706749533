import React, { useEffect } from 'react'
import './privacyTerms.css'
import Header from '../../components/homepage_components/Header/Header'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="privacy_terms">
            {' '}
            <Header className="header_div" />
            <div className="privacy_terms_container">
                <h3>TERMS OF USE OF ‘Circle4Life</h3>
                <p>
                    Download, access and use of the CIRCLE4LIFE (“App”) is subject to the following terms and conditions
                    (hereinafter ‘Terms’):
                </p>
                <p>
                    <span className="underlined_headings">About the App:</span> App is a personalized mobile application
                    developed by Tata Consultancy Services for TCS Associates, to encourage them to adopt a (more)
                    sustainable lifestyle through simple everyday actions.
                </p>{' '}
                <p>
                    The App is available for free download for TCS employees, currently for select mobile operating
                    system(s) / platform(s) and versions thereof. However, access to the App, or certain sections of the
                    App, may require registration and authentication, and is subject to Your (users) compliance with the
                    terms and conditions set forth herein and all applicable laws, and only for your own personal,
                    non-commercial use and not for any other purpose whatsoever.
                </p>{' '}
                <p>
                    This is a legal agreement between (a) You and (b) TATA Consultancy Services Limited and its
                    affiliates ("TCS") that governs your use of App.
                </p>
                <p>
                    By selecting "agree", or by taking any step to install, access or use the app, you (1) represent
                    that you are of the legal age of majority in your state, province jurisdiction of residence you
                    agree to be bound by the terms of this agreement. If you do not accept the terms, do not download or
                    use the app. By downloading, access and/or use of the app, you agree to these terms.
                </p>{' '}
                <p>
                    <span className="underlined_headings">Eligibility and Compliance with Laws:</span> You affirm to be
                    above the statutory age for adults required in the Your jurisdiction. The App may deliver content
                    which may be inappropriate for users below statutory age. You need to have internet access and a
                    valid account login provision made available for authentication hereunder. Further, You must ensure
                    that Your use of the App is lawful in accordance with the laws of the state of which You are a
                    citizen and/or resides, and/or happens to be at the time the App is downloaded, accessed or used.
                    Neither TCS nor its affiliates nor any Related Parties (as hereinafter defined) shall be taken to
                    make any representations, express or implied, as to the lawfulness of any individual's access and
                    use of the App. You acknowledge and agree that this information will be permanently mapped and
                    stored in App database along with your user account information. You shall comply with all laws and
                    regulations of the country you reside in and other countries ("Export Laws") to ensure that the App
                    is not (1) exported, re-exported, directly or indirectly, in violation of Export Laws, or (2) used
                    for any purpose prohibited by Export Laws. You further agree that you will not use the App for any
                    purpose prohibited under the applicable law.{' '}
                </p>
                <p>
                    <span className="underlined_headings">Registration and Authentication:</span> Currently the only way
                    to register and sign up to the App is through an authentication process - TCS Associates after
                    entering their TCS email id an OTP will be sent to their TCS email account. Through which associates
                    can login / register to the application In case you would like to discontinue usage of App and
                    uninstall/delete the App from your device. Your Personal Data shall not be retained for longer than
                    it is required for the purposes for which the information may lawfully be used or is otherwise
                    required under any law for the time being in force.
                </p>{' '}
                <p>
                    <span className="underlined_headings">Disclaimer and Limitation of Liability:</span> The app and its
                    services/content is provided on and “as is” and “as available” basis. TCS and any other parties
                    involved in the creation, production or delivery of the app and its services / content, expressly
                    disclaim any and all warranties, express and implied, including but not limited to any warranties of
                    accuracy, reliability, availability, title, merchantability, non-infringement, fitness for a
                    particular purpose, or that the app and its services / content will meet your requirement, or will
                    be uninterrupted, timely, secure, accurate, complete, virus-free or error-free. You acknowledge and
                    agree that your decision to download and use any information, services or products made available by
                    or through this app or to take any action or refrain from taking any action as a result of your use
                    of this app and its services/content will be your discretion and decision alone, based solely on
                    your own evaluation. Neither this app nor any information/content provided by or through this app
                    constitute an offer or solicitation to sell any product or service. In no event shall tcs or any of
                    its affiliates, subsidiaries, licensors, suppliers and their respective employees, officers,
                    directors, and agents (“related parties”) be liable for any direct, special, consequential,
                    incidental or indirect damages whatsoever arising out of the access and use of or inability to use,
                    reliance on, the app and its services/content, and arising from third party’s access to personal
                    information input in or through the app, even if the tcs is aware of the possibility of such losses,
                    damages and known defects.
                </p>{' '}
                <p>
                    The information provided on this app is intended for general consumer understanding and
                    entertainment only. The information provided is not intended to be a substitute for research
                    continuously evolves, we do not guarantee the accuracy, completeness, or timeliness of any
                    information presented on this website.
                </p>{' '}
                <p>
                    <span className="underlined_headings">Intellectual Property Rights:</span> TCS is the sole and
                    exclusive owner of the App. All intellectual property rights in the App and its services / content,
                    including but not limited to copyright, trademarks, patents, trade secrets and design of the App and
                    its services / content is owned by TCS and/or its licensors/TP Content providers. You agree that You
                    shall not remove, obscure, or alter any proprietary rights notices (including copyright and
                    trademark notices) which may be affixed to or contained within the App and its service / content.
                    Any use of the App and its services / contents, including copying, publishing, distributing,
                    downloading, transmitting, storing, archiving whether in whole or part, other than for permitted use
                    is prohibited without the express consent of TCS. You are prohibited from copying, modifying,
                    transmitting, distributing, selling, displaying, communicating, publishing, posting, creating
                    derivative works of, licensing or reproducing or in any way exploit any content including text,
                    images, graphics, user and visual interfaces, trademarks, logo, computer code, documentation and
                    other content, opinions, analysis, statistics, information or photographs made available through or
                    on this App without written permission from TCS or by the owners of that content. Any comments,
                    suggestions, improvements or other communications from You to TCS regarding the App and its services
                    / contents shall become the sole property of TCS. TCS has the right to use them without any
                    compensation or attribution whatsoever. Nothing herein will have the effect of granting any right,
                    title or interest in the App and its services / content or in or any intellectual property rights
                    therein in Your favour.
                </p>
                <p>
                    <span className="underlined_headings">Third Party Content and Websites: </span>
                    Through the use of App, You will access third party content or content services from a third party
                    or services provided or made available by or through a third party website, the accuracy or
                    completeness of the materials or the reliability of the website or any advice, opinion, statement,
                    reports, analysis, statistics or other information (collectively the “TP Content”) displayed or
                    distributed or sold through it or based on the TP Content or website, is not warranted by TCS. TCS
                    is providing the TP Content, or link to the third party websites only for informational purpose only
                    as an additional resource for convenience and does not provide an endorsement, sponsorship or
                    recommendation to such TP Content, products, services or third party websites. You acknowledge and
                    agree that the access to TP Content or such websites is governed by the terms and conditions of such
                    third party and You undertake to abide by the same. In case any TP Content causes infringement
                    issues, violation of copyright or anything objectionable You may look up the content identifier
                    label and accordingly coordinate with the respective content originator for the same{' '}
                </p>
                <p>
                    You acknowledge that reliance on any TP Content, product or services made available through the use
                    of the App, suitability for any contemplated use, manner of use and whether any intellectual
                    property rights are infringed shall be at Your own discretion, evaluation, risk and consequences.
                    The user shall take into account that TCS does not own or produce any of the data or information or
                    content which is being used through the App. TCS is only aggregating and linking data from several
                    sources through programmatic automation. In particular, TCS does not accept any liability arising
                    out of, or in relation to, any TP Content or website, including without limitation, any allegation
                    relating to TP Content accessed or purchased through the third party website, or that any TP Content
                    infringes the intellectual property rights of any person. The aggrieved party should approach the
                    respective source/entity if they wish to avail any legal remedy under the law. TCS should not be
                    made party to such claims/litigation filed by the aggrieved party. TCS shall not be liable and takes
                    no responsibility for non-compliance by the third party to take remedial actions as requested by
                    You. If you believe that any work has been improperly copies to the APP, such that it constitutes
                    infringement, please provide the following information:
                </p>
                <p>
                    <span className="underlined_headings">Notice/s:</span>
                    <ul className="dashed_listElements">
                        <li>
                            A description of the copyrighted work that You claim has been infringed, including the URL
                            (i.e., web page address) of the location where the copyrighted work exists or a copy of the
                            copyrighted work
                        </li>
                        <li>
                            {' '}
                            Identification of the URL or other specific location on the Service where the material that
                            You claim is infringing is located{' '}
                        </li>
                        <li>Your address, telephone number, and email address</li>
                        <li>
                            A statement that You have a good faith belief that the disputed use is not authorized by the
                            copyright owner, its agent, or the law
                        </li>
                        <li>
                            A statement by You, made under penalty of perjury, that the above information in Your notice
                            is accurate and that You are the copyright owner or authorized to act on the copyright
                            owner’s behalf.
                        </li>
                    </ul>
                </p>
                <p>
                    <span className="underlined_headings">Open Source Components: </span>The App and its
                    services/content may contain open source components (“OSS”). You hereby agree and acknowledge that
                    any use of the OSS items are provided ‘AS IS’ without any warranty whatsoever from TCS and shall
                    always be governed by and subject to the terms and condition of the relevant OSS license agreement.
                    In the event of any conflict between these Terms of Use and those in the relevant OSS license
                    agreement, the terms of the OSS license agreement shall prevail with respect to use of that relevant
                    OSS. The list of OSS contained in this version of the App can be found at: DETAILS OF OPEN SOURCE
                    SOFTWARE (OSS) CONTAINED IN THE APP.{' '}
                </p>
                <p>
                    <span className="underlined_headings">Prohibited Use:</span> Unless expressly permitted by TCS in
                    writing, You may not and not permit anyone else to: (i) copy, modify, create a derivative work of,
                    reverse engineer, decompile or otherwise attempt to extract the source code of the App, its service
                    / contents or any part thereof; (ii) intentionally interfere with or damage operation of the App or
                    any users’ enjoyment of the App, by any means, including but not limited to uploading or otherwise
                    disseminating viruses, adware, spyware, worms, or other malicious code; (iii) use any robot, spider,
                    other automatic device/process, or manual process to monitor or copy the App or its contents; (iv)
                    share the authentication details with any other user or circumvent, bypass, delete or remove any
                    form of protection, or usage, functionality or technical restrictions/limitation, or to enable
                    functionality disabled by TCS; (v) access the App and its services / content from any devices which
                    are not at all times under Your possession or control; (vi) gain unauthorized access to the App or
                    misuse the App and its services / content in any manner whatsoever or use the App for any illegal,
                    unauthorized, fraudulent or otherwise improper purpose or effect.
                </p>
                <p>
                    <span className="underlined_headings">Changes:</span> TCS reserves the right to withdraw, amend,
                    suspend or terminate the App and/or service / content or any part thereof provided on or through the
                    App without notice. TCS aims to update the App regularly and may change, alter, modify or delete the
                    content and/or its features or services or any part thereof at any time, including without
                    limitation, for each mobile operating system / platform or version thereof on which the App is made
                    available, or for different versions of the App, at its sole discretion and the same shall be
                    binding on You. You agree and understand that services / content / features may differ for each
                    mobile operating system / platform or versions thereof on which the App is made available or
                    different versions of the App. TCS may change the Terms of Use from time to time without notice
                    which become effective as soon as they are posted on this page. It is your responsibility to check
                    for updates and Your continued use the App after the posting of these changes shall mean Your
                    acceptance of such changes.{' '}
                </p>
                <p>
                    <span className="underlined_headings">Termination:</span> The license granted herein is effective
                    until terminated or rejected. TCS shall at its sole discretion be entitled at any time without any
                    notice withdraw access and/or terminate any permission(s) or license granted to You. If You breach
                    the provisions of the Terms of Use or violate any law, rule or regulation of United States or any
                    other country, Your access and use of the App is automatically terminated and any subsequent access
                    and use is unauthorized. TCS shall have the right, but without any obligation, to restrict access to
                    and/or immediately remove or block any and all contents uploaded by the user that violates these
                    terms and conditions or is non-compliant in TCS’ sole discretion and TCS shall have the right to
                    take appropriate measures available to it under the applicable law.TCS and all its Related Parties
                    described hereinabove stand indemnified by You from and against all liabilities, costs or
                    consequences arising out of proceedings or matters arising thereto resulting from Your access and
                    use of the App and its services / content and/or breach of the terms and conditions specified
                    herein. On termination, You must destroy any content/materials downloaded immediately and on request
                    provide TCS with the declaration of destruction of such contents that have been used/accessed before
                    the termination of this T&C.
                </p>
                <p>
                    <span className="underlined_headings">Privacy :</span> We respect the privacy of individuals, we
                    recognize the importance of managing, protecting, storing, processing and disclosing your personal
                    data. We will collect, store, use and disclose your personal data in accordance with the applicable
                    privacy laws. Personal information, if any, collected through the App may be transferred and stored
                    and processed outside the country you reside or in any other country in which TCS or its service
                    providers maintain facilities or in which TCS and its Related Parties operate for user
                    authentication, role authorization, permissions, record keeping, statistical analysis and
                    improvement purposes only. By using this App, You consent to the use, processing and transfer of
                    Your personal information outside of Your present country/state. You acknowledge and agree that TCS
                    may automatically check the UDID of Your device, version of the App that You are utilizing and may
                    provide upgrades or fixes to the App that may automatically download on to Your device. By accessing
                    or using the App and its services / content, You expressly consent and agree that TCS shall have the
                    right, but no obligation, to monitor Your access and use of the App, delete, use any Page 4 of 6
                    communication, views, opinion, comments, ideas, documents, content etc expressed/input/uploaded by
                    You on or through the App, without any further permissions or approval from You, and covenant that
                    this will not violate Your privacy rights. You agree to assist TCS in relation to any investigation
                    and remedy of any claim, allegation, action, suit, proceeding or litigation with respect to alleged
                    unauthorized access and use of the App and its services / content. For further details on TCS’s
                    privacy policy, please visit at the link provided in the APP (Privacy Policy) and manage or delete
                    your data with TCS, you may write to the DPO identified in the Privacy Policy.{' '}
                </p>
                <p>
                    You agree and consent that TCS will store any/all of the personal information by use cookies (ID
                    cookies and/or persistent cookies, to make it easier for you to navigate our App. A session ID
                    cookie expires when you close the App. A persistent cookie remains on your device hard disk for an
                    extended period of time), log files (includes internet protocol (IP) addresses, browser type,
                    internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and
                    clickstream data), clear gifs, to create a “profile" and portfolio preferences within the App,
                    enrollment form or account settings. The same will be linked your personally identifiable
                    information to your login access, to information in the profile, in order to provide a more accurate
                    understanding of the App and analyze trends, to administer the App, to track users’ movements around
                    the App and to gather demographic information about our user base as a whole. TCS will use various
                    technologies for the purposes of identifying unique user visits as opposed to aggregate hits, such
                    data linking information gathered by clear gifs to a user's personally identifiable information is
                    used for internal tracking purposes only. TCS shall always reserve the right to disclose your
                    personally identifiable information as required by law and disclosure is necessary to protect our
                    rights and/or to comply with a judicial proceeding, court order, or legal processes.
                </p>
                <p>
                    {' '}
                    If You intend your account identifier to be deleted permanently from the App database, you may do so
                    by writing a mail to the support group specified in the Privacy Policy. TCS will take at least
                    thirty (30) business days from the date of receipt of request to delete the Personal Data provided
                    it is/may not otherwise required under any law for the time being in force. However, already
                    published reports and analytical collated data also having user’s details may not be deleted.{' '}
                </p>
                <p>
                    <span className="underlined_headings">Interactive Services:</span> If at any time this App provides
                    any features enabling You to give Your input including but not limited to by way of chat rooms,
                    blogs, message board facilities, polls, etc. (“Interactive Services”), TCS may, but is not obligated
                    to, monitor and/or moderate any Interactive Service provide on or through our App. Please be careful
                    about what information, including personal information, You disclose in this way.
                </p>
                <p>
                    Such Interactive Services are provided on ‘AS IS’ and ‘AS AVAILABLE’ basis and only on select mobile
                    operating system(s) / platform(s) and versions thereof. You represent, warrant and covenant that You
                    will not upload, post, transmit, distribute, or otherwise publish through this App or any
                    Interactive Service provided hereunder any materials / content which, are unlawful, threatening,
                    abusive, libelous, defamatory, obscene, vulgar, offensive, pornographic, profane, sexually explicit,
                    or indecent; constitute or encourage conduct that would constitute a criminal offense, give rise to
                    civil liability or otherwise violate law; violate, plagiarize or infringe the rights of any third
                    party including, without limitation copyright, trademark, patent, rights of privacy or publicity, or
                    any other right of any third party; contain a virus or other harmful or potentially harmful
                    component; contain any information advertising of any kind; constitute or contain false or
                    misleading indications of origin or statements of fact; or restrict or inhibit any other user from
                    using and enjoying the App and/or its services or content. TCS expressly excludes our liability for
                    any loss or damage arising from the use of any Interactive Service by You or any other person in
                    contravention of these Terms, whether the service is moderated or not.
                </p>
                <p>
                    <span className="underlined_headings">Hosting:</span> The server side of the App is hosted on public
                    Google cloud infrastructure. You acknowledge and agree to the public cloud infrastructure / web
                    services terms of such third party service providers, including without limitation, those relating
                    to security and data privacy, availability / non-availability, indemnification and limitation of
                    liability.
                </p>
                <p>
                    <span className="underlined_headings">Cellular or Wireless Service:</span> The App transmits data
                    via Your wireless carrier or accessible Wi-Fi network. TCS is not responsible or liable for any data
                    charges associated with the use of App or any of its features. The service may occasionally be
                    restricted, interrupted or discontinued due to conditions such as, but not limited to: app design,
                    network coverage, government regulation, or other situations beyond TCS’s control.
                </p>
                <p>
                    <span className="underlined_headings">Force Majeure:</span> Neither party shall be in breach of this
                    agreement nor liable for delay in performing, or failure to perform, any of its obligations under
                    this agreement if such delay or failure result from force majeure or events, circumstances or causes
                    beyond its reasonable control.
                </p>
                <p>
                    <span className="underlined_headings">Jurisdiction and Applicable Law:</span> These Terms of Use
                    shall, without reference to conflict of law rules, be governed by and construed in accordance with
                    the laws of Singapore and You agree that the courts in Singapore shall have the exclusive
                    jurisdiction over any matters arising out of, or in relation to, the App or Your use of the App. TCS
                    may seek injunctive or other relief in any state, federal, or national court of competent
                    jurisdiction for any actual or alleged infringement of intellectual property or other proprietary
                    rights of TCS, it affiliates and/or their respective licensors. The parties hereby agree that the
                    United Nations Convention for the International Sale of Goods shall not apply to this agreement.
                </p>
                <p>
                    <span className="underlined_headings">Dispute Resolution:</span> All disputes arising out of or in
                    connection with the mentioned terms & conditions shall be settled by Arbitration in accordance with
                    the said Rules of Singapore International Arbitration (SIAC) by a single arbitrator appointed in
                    accordance with the Rules. The decision of such an arbitrator shall be final and binding on both the
                    Parties. The cost for the Arbitration proceedings shall be borne by the losing Party. The venue of
                    arbitration shall be Singapore and the language of proceedings shall be English. Nothing herein
                    shall prohibit TCS from seeking a temporary restraining order, preliminary injunction or other
                    provisional relief if, in its judgment, such action is necessary to avoid irreparable damage, to
                    preserve the status quo or to prevent or stop the violation of these term and conditions and/or
                    infringement of intellectual property rights or other proprietary rights of TCS, its affiliates
                    and/or their respective licensors.
                </p>
                <p>
                    <span className="underlined_headings">Entire Agreement: </span>These terms and conditions and any
                    additional terms form a legally binding agreement between You and TCS and are the entire agreement
                    between the user and TCS regarding access and use of the App and supersede any prior or
                    contemporaneous, conflicting or additional understandings or agreements or communications (written
                    or oral). If there is any contradiction between these Terms of Use and any additional terms in
                    relation to a service, then the additional terms shall take precedence in relation to that service.
                </p>
                <p>
                    <span className="underlined_headings"> Waiver:</span> Failure to exercise or delay in exercising any
                    right or remedy under these Terms of Use or law by TCS will not constitute a waiver of rights or
                    remedy nor shall it prevent exercising that right or remedy in whole or in part in future.
                </p>
                <p>
                    <span className="underlined_headings">Severability:</span> If any provision of these Terms or Use or
                    part thereof is held by any court of competent jurisdiction to be invalid, unenforceable or illegal,
                    the other provisions shall remain unaffected and shall remain in force.{' '}
                </p>
                <p>
                    <span className="underlined_headings">Headings:</span> The clause headings are for the purposes of
                    convenience/ease of reference and are not to be used in interpretation of these Terms. Copyright ©
                    2020 Tata Consultancy Services Limited. All Rights Reserved. I agree to Terms of Use. Page 6 of 6
                    DETAILS OF OPEN SOURCE SOFTWARE (OSS) CONTAINED IN THE APP Componen t Versio n Home Page License
                    Attribution Note License URL LIST OF OSS COMPILED NA LIST OF OSS NOT COMPILED Component Versio n
                    Home Page License Attribution Note License URL
                </p>
            </div>
        </div>
    )
}

export default Terms
